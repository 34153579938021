<template>
    <div>
      <WarningReport></WarningReport>
    </div>
  </template>
  
  <script>
  import WarningReport from '@/components/report/WarningReport.vue';
  export default {
      components: {
          WarningReport
      }
  }
  </script>
  
  <style>
  
  </style>